<template>
  <div class="mt-5 pt-2">
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-32 mb-3">Авторизуйтесь</div>
      <div class="mb-4">
        Введите email и пароль
      </div>
      <a-form
        :model="loginForm"
        :rules="rules"
        layout="vertical"
        class="mb-4"
        @finish="handleFinish"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item name="email">
          <a-input v-model:value="loginForm.email" placeholder="Email" />
        </a-form-item>
        <a-form-item name="password">
          <a-input v-model:value="loginForm.password" placeholder="Password" type="password" />
        </a-form-item>
        <a-button type="primary" html-type="submit" class="text-center w-100" :loading="loading">
          <strong>Войти</strong>
        </a-button>
      </a-form>
      <router-link to="/auth/forgot-password" class="vb__utils__link d-none">
        Забыли пароль?
      </router-link>
    </div>
    <div class="text-center pt-2 mb-auto">
      <span class="mr-2">Нет аккаунта?</span>
      <router-link to="/auth/register" class="vb__utils__link">
        Пройдите регистрацию
      </router-link>
    </div>
  </div>
</template>
<script>
import {computed, reactive, ref} from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'VbLogin',
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)
    const loading = computed(() => store.getters['user/user'].loading)
    const rules = {
      email: [
        {
          required: true,
          message: 'Пожалуйста введите email!',
          trigger: 'change',
        },
      ],
      password: [{ required: true, message: 'Пожалуйста введите пароль!', trigger: 'change' }],
    }
    const loginForm = reactive({
      email: '',
      password: '',
    })

    const changeAuthProvider = value => {
      store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
    }
    const handleFinish = values => {
      store.dispatch('user/LOGIN', { payload: values })
    }
    const handleFinishFailed = () => {
      // console.log(errors)
    }

    return {
      settings,
      loading,
      rules,
      loginForm,
      changeAuthProvider,
      handleFinish,
      handleFinishFailed,
    }
  },
  // data: function () {
  //   return {
  //     rules: {
  //       email: [{ required: true, message: 'Please input your email!', trigger: 'change' }],
  //       password: [{ required: true, message: 'Please input password!', trigger: 'change' }],
  //     },
  //     loginForm: {
  //       email: 'demo@visualbuilder.cloud',
  //       password: 'VisualBuilder',
  //     },
  //   }
  // },
  // computed: {
  //   ...mapState(['settings']),
  //   loading() {
  //     return this.$store.state.user.loading
  //   },
  // },
  // methods: {
  //   changeAuthProvider(value) {
  //     this.$store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
  //   },
  //   handleFinish(values) {
  //     this.$store.dispatch('user/LOGIN', { payload: values })
  //   },
  //   handleFinishFailed(errors) {
  //     console.log(errors)
  //   },
  // },
}
</script>
<style lang="scss" module>
@import '@/@vb/components/Auth/style.module.scss';
</style>
